<template>
  <IntakeFormBody />
</template>

<script>
import IntakeFormBody from '@/components/bodies/IntakeForm.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'IntakeForm',
  components: {
    IntakeFormBody
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
