<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn">
    <div class="mb-1">
      <h1 class="mb-1">Tell us about your work and business</h1>
      <p class="text-surface-800">
        This allows us to customize your Bolster experience and provide you with the tools and
        resources most relevant to your work.
      </p>
    </div>

    <div class="mt-8 mb-3">
      <p class="text-xl">About your work</p>

      <span class="flex flex-col mt-2">
        <div class="flex flex-row">
          <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
          <p class="ml-1">Which categories best describe your work?</p>
        </div>
        <choose
          multiple
          highlightIfEmpty
          size="md"
          :validate="{ required: true }"
          schema="company:trade_type_ids"
          v-model="trade_type_ids"
          full
          btnClass="border-surface-300 bg-white hover:bg-surface-100"
          ref="trade_type_ids"
        />
      </span>

      <div class="flex flex-row mt-6 gap-x-3 gap-y-6 flex-wrap md:flex-nowrap">
        <span class="flex flex-col w-full md:w-[50%]">
          <div class="flex flex-row">
            <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
            <p class="ml-1">How many quotes do you send each month?</p>
          </div>
          <choose
            highlightIfEmpty
            size="md"
            :validate="{ required: true }"
            @input="forceUpdate"
            v-model="onboarding.quotes_per_month"
            ref="quotes_per_month"
            full
            btnClass="border-surface-300 bg-white hover:bg-surface-100"
            :staticSet="[
              {
                value: '0',
                text: 'None yet'
              },
              {
                value: '1-5',
                text: '1 to 5'
              },
              {
                value: '5-10',
                text: '5 to 10'
              },
              {
                value: '10-20',
                text: '10 to 20'
              },
              {
                value: '20+',
                text: 'Over 20'
              }
            ]"
          />
        </span>

        <span class="flex flex-col w-full md:w-[50%]">
          <div class="flex flex-row">
            <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
            <p class="ml-1">How many of your quotes turn into booked jobs?</p>
          </div>
          <choose
            highlightIfEmpty
            size="md"
            :validate="{ required: true }"
            @input="forceUpdate"
            v-model="onboarding.quotes_booked"
            ref="quotes_booked"
            full
            btnClass="border-surface-300 bg-white hover:bg-surface-100"
            :staticSet="[
              {
                value: 0.0,
                text: '0%'
              },
              {
                value: 0.1,
                text: '10%'
              },
              {
                value: 0.2,
                text: '20%'
              },
              {
                value: 0.3,
                text: '30%'
              },
              {
                value: 0.4,
                text: '40%'
              },
              {
                value: 0.5,
                text: '50%'
              },
              {
                value: 0.6,
                text: '60%'
              },
              {
                value: 0.7,
                text: '70%'
              },
              {
                value: 0.8,
                text: '80%'
              },
              {
                value: 0.9,
                text: '90%'
              },
              {
                value: 1.0,
                text: '100%'
              }
            ]"
          />
        </span>
      </div>

      <div class="flex flex-row mt-6 mr-3">
        <span class="flex flex-col w-full">
          <div class="flex flex-row">
            <p class="ml-1">Is your business seasonal?</p>
          </div>
          <SelectionToggle
            buttonClass="flat w-full"
            active-class="info"
            inactive-class="white"
            @input="forceUpdate"
            v-model="onboarding.is_seasonal"
            ref="is_seasonal"
            :options="[
              {
                value: 0,
                text: 'Not Seasonal'
              },
              {
                value: 1,
                text: 'Seasonal'
              }
            ]"
          />
        </span>
      </div>
    </div>

    <div class="mt-8 mb-2">
      <p class="text-xl">Company scale</p>

      <div class="flex flex-row mt-2 gap-x-3 gap-y-6 flex-wrap md:flex-nowrap">
        <span class="flex flex-col w-full md:w-[50%]">
          <div class="flex flex-row">
            <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
            <p class="ml-1">What is your estimated yearly revenue?</p>
          </div>
          <choose
            highlightIfEmpty
            size="md"
            :validate="{ required: true }"
            @input="forceUpdate"
            v-model="onboarding.yearly_revenue"
            ref="yearly_revenue"
            full
            btnClass="border-surface-300 bg-white hover:bg-surface-100"
            :staticSet="[
              {
                value: '0-50000',
                text: '$0 to $50,000'
              },
              {
                value: '50000-100000',
                text: '$50,000 to $100,000'
              },
              {
                value: '100000-500000',
                text: '$100,000 to $500,000'
              },
              {
                value: '500000-1000000',
                text: '$500,000 to $1,000,000'
              },
              {
                value: '1000000-2000000',
                text: '$1,00,000 to $2,000,000'
              },
              {
                value: '2000000+',
                text: 'Over $2,000,000'
              }
            ]"
          />
        </span>

        <span class="flex flex-col w-full md:w-[50%]">
          <div class="flex flex-row">
            <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
            <p class="ml-1">What percentage of work do you subcontract?</p>
          </div>
          <choose
            highlightIfEmpty
            size="md"
            :validate="{ required: true }"
            @input="forceUpdate"
            v-model="onboarding.work_subcontracted_percent"
            ref="work_subcontracted_percent"
            btnClass="border-surface-300 bg-white hover:bg-surface-100"
            full
            :staticSet="[
              {
                value: '0.00',
                text: '0%'
              },
              {
                value: '0.25',
                text: '25%'
              },
              {
                value: '0.50',
                text: '50%'
              },
              {
                value: '0.75',
                text: '75%'
              },
              {
                value: '1.00',
                text: '100%'
              }
            ]"
          />
        </span>
      </div>

      <div class="flex flex-row mt-6 gap-x-3 gap-y-6 flex-wrap md:flex-nowrap">
        <span class="flex flex-col w-full md:w-[50%]">
          <div class="flex flex-row">
            <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
            <p class="ml-1">How many employees do you have?</p>
          </div>
          <field
            v-model="onboarding.employees"
            class="!bg-flame-white !w-full !border-surface-300"
            :validate="{
              required: true,
              format: /^\d+$/
            }"
            @input="forceUpdate"
            ref="employees"
          />
          <p class="text-sm text-surface-600/75 ml-1 mt-0.5">Including yourself</p>
        </span>

        <span class="flex flex-col w-full md:w-[50%]">
          <div class="flex flex-row">
            <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-xs" />
            <p class="ml-1">How many employees will be using Bolster?</p>
          </div>
          <field
            v-model="onboarding.employees_using_software"
            class="!bg-flame-white !w-full !border-surface-300"
            :validate="{
              required: true,
              format: /^\d+$/
            }"
            @input="forceUpdate"
            ref="employees_using_software"
          />
          <p class="text-sm text-surface-600/75 ml-1 mt-0.5">
            Including yourself - must be less than total employees
          </p>
        </span>
      </div>
    </div>

    <div class="bg-surface-200 mt-6 h-px" />
    <danger
      v-if="invalidFields.length"
      :rounded="false"
      :flat="true"
      :border="false"
      :highlight="true"
      class="xs mt-3"
    >
      <p class="text-danger">
        The following fields must be filled out correctly: {{ invalidFields.join(', ') }}
      </p>
    </danger>
    <div class="flex mt-3 justify-between">
      <div>
        <FormButton
          @click="saveData('last')"
          ref="lastButton"
          text="Previous"
          leftArrow
          muted
          showLoading
        />
      </div>
      <div>
        <FormButton
          @click="saveData('next')"
          ref="nextButton"
          text="Continue"
          rightArrow
          showLoading
        />
      </div>
    </div>
  </span>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import FormButton from '@/components/ui/FormButton.vue'

export default {
  name: 'IntakeCompanyDetails',
  mixins: [ObjectManipulator('company', false, 'Company'), BodyMixin],
  components: {
    FormButton
  },
  props: {
    // Used by ObjectManipulator to pull and update company data in the vuex store
    object: Object
  },
  data() {
    return {
      invalidFields: [],
      onboarding: {}
    }
  },
  async mounted() {
    await setTimeout(() => {
      if (this.oMeta.onboarding) {
        this.onboarding = this.oMeta.onboarding
      }
      if (!this.onboarding.is_seasonal) {
        this.onboarding.is_seasonal = 0
      }
      this.forceUpdate()
    }, 500)
  },
  methods: {
    /**
     * Needed since objects won't react when their values are updated  Thus, we
     * need to manually update the whole oMeta object to force reactivity.
     */
    forceUpdate() {
      setTimeout(() => {
        this.oMeta = { ...this.oMeta, onboarding: this.onboarding }
      }, 100)
    },
    validateData() {
      this.invalidFields = []
      if (!this.$refs.trade_type_ids.value) {
        this.invalidFields.push('work categories')
      }
      if (!this.$refs.quotes_per_month.value) {
        this.invalidFields.push('quotes per month')
      }
      if (!this.$refs.quotes_booked.value) {
        this.invalidFields.push('quotes booked')
      }
      if (!this.$refs.yearly_revenue.value) {
        this.invalidFields.push('yearly revenue')
      }
      if (!this.$refs.work_subcontracted_percent.value) {
        this.invalidFields.push('work subcontracted')
      }
      if (!this.$refs.employees.valid) {
        this.invalidFields.push('number of employees')
      }
      if (!this.$refs.employees_using_software.valid) {
        this.invalidFields.push('number of employees using Bolster')
      }
      if (this.$refs.employees.valid && this.$refs.employees_using_software.valid) {
        const employees = Number(this.$refs.employees.value)
        const employeesUsingSoftware = Number(this.$refs.employees_using_software.value)
        if (employees < employeesUsingSoftware) {
          this.invalidFields.push(
            "the number of employees using Bolster can't exceed your total number of employees"
          )
        }
        if (employees <= 0) {
          this.invalidFields.push('you must have at least 1 employee')
        }
        if (employeesUsingSoftware <= 0) {
          this.invalidFields.push('you must have at least 1 employee using Bolster')
        }
      }
      if (this.invalidFields.length) {
        return false
      }
      return true
    },
    async saveData(page) {
      const data = {
        trade_type_ids: this.trade_type_ids,
        company_default_markup: this.company_default_markup,
        oMeta: {
          ...this.oMeta,
          onboarding: {
            quotes_per_month: this.onboarding.quotes_per_month,
            quotes_booked: this.onboarding.quotes_booked,
            is_seasonal: this.onboarding.is_seasonal,
            yearly_revenue: this.onboarding.yearly_revenue,
            work_subcontracted_percent: this.onboarding.work_subcontracted_percent,
            employees: this.onboarding.employees,
            employees_using_software: this.onboarding.employees_using_software
          }
        }
      }
      if (this.validateData()) {
        await this.$store.dispatch('Company/submitOnboardingData', {
          companyId: this.$store.state.session.scope.company,
          data
        })
        if (page === 'next') this.$emit('nextPage')
      }
      this.$refs.nextButton.doneLoading()
      if (page === 'last') this.$emit('lastPage')
    }
  }
}
</script>
