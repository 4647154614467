<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn">
    <div class="mb-1">
      <h1 class="mb-1">Invite your team</h1>
      <p class="text-surface-800">
        Each member of your team will recieve a welcome email with a secure link allowing them to
        login automatically. You can always invite more team members later.
      </p>
    </div>

    <div class="mt-4">
      <InviteUsers :showTitle="false" ref="inviteUsers" />
    </div>

    <div class="bg-surface-200 mt-6 h-px" />
    <warning
      v-if="hasWarned"
      :rounded="false"
      :flat="true"
      :border="false"
      :highlight="true"
      class="xs mt-3"
    >
      <p class="text-danger">
        Are you sure you want to continue? Make sure to press Send Invitation(s) before proceeding.
      </p>
    </warning>
    <div class="flex mt-3 justify-between">
      <div><FormButton @click="$emit('lastPage')" text="Previous" leftArrow muted /></div>
      <div><FormButton @click="onContinue" text="Continue" rightArrow /></div>
    </div>
  </span>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'
import InviteUsers from '@/components/users/InviteUsers.vue'

export default {
  name: 'IntakeInviteUsers',
  components: {
    FormButton,
    InviteUsers
  },
  data() {
    return {
      hasWarned: false
    }
  },
  methods: {
    onContinue() {
      if (!this.hasWarned && this.$refs.inviteUsers?.validUsers?.length) {
        this.hasWarned = true
      } else {
        this.$emit('nextPage')
      }
    }
  }
}
</script>
