<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn">
    <div class="mb-1">
      <h1 class="mb-1">Company defaults</h1>
      <p class="text-surface-800">
        Set the default profit amount on your items and enter your company's labor rates to make
        your first estimate as seamless as possible.
      </p>
    </div>

    <div class="mt-8 mb-3">
      <div class="flex flex-row items-center mb-2">
        <p class="text-xl">Default profit</p>
        <Help class="text-xs ml-2">
          <template #title> Understanding profit </template>
          <CompanyMarkupHelp />
        </Help>
      </div>
      <CompanyMarkupAdjuster :object="this.$store.state.session.company" />
    </div>

    <div class="mt-8 mb-3">
      <div class="flex flex-row items-center mb-2">
        <p class="text-xl">Labor rates</p>
        <Help class="text-xs ml-2">
          <template #title> Understanding labor rates </template>
          <div class="flex flex-col">
            <p><b>Labor rate name:</b></p>
            <p class="mb-4">A descriptive name for your labor rate.</p>
            <p><b>Labor rate:</b></p>
            <p class="mb-4">
              The <i>hourly</i> rate of labor. Includes burden costs such as payroll pension plans,
              benefits, employment insurance, workers compensation or other safety insurance, and
              gross hourly pay.
            </p>
            <p><b>Crew types:</b></p>
            <p class="mb-4">
              The types of crews that this labor rate applies to. Multiple can be chosen.
            </p>
          </div>
        </Help>
      </div>
      <div class="flex mt-2">
        <AddLaborRates ref="laborRates" :showSubmit="false" />
      </div>
    </div>

    <div class="bg-surface-200 mt-6 h-px" />
    <div class="flex mt-3 justify-between">
      <div>
        <FormButton
          @click="saveData('last')"
          ref="lastButton"
          text="Previous"
          leftArrow
          muted
          showLoading
        />
      </div>
      <div>
        <FormButton
          @click="saveData('next')"
          ref="nextButton"
          text="Continue"
          rightArrow
          showLoading
        />
      </div>
    </div>
  </span>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import FormButton from '@/components/ui/FormButton.vue'
import CompanyMarkupAdjuster from '@/components/company/CompanyMarkupAdjuster.vue'
import CompanyMarkupHelp from '@/components/company/CompanyMarkupHelp.vue'
import AddLaborRates from '@/components/ui/AddLaborRates.vue'

export default {
  name: 'IntakeCompanyDefaults',
  mixins: [ObjectManipulator('company', false, 'Company'), BodyMixin],
  components: {
    FormButton,
    CompanyMarkupAdjuster,
    CompanyMarkupHelp,
    AddLaborRates
  },
  props: {
    // Used by ObjectManipulator to pull and update company data in the vuex store
    object: Object
  },
  methods: {
    async saveData(page) {
      const data = {
        company_default_markup: this.company_default_markup
      }
      await this.$store.dispatch('Company/submitOnboardingData', {
        companyId: this.$store.state.session.scope.company,
        data
      })
      await this.$refs.laborRates.saveRates()

      if (page === 'next') this.$emit('nextPage')
      if (page === 'last') this.$emit('lastPage')
      this.$refs.nextButton.doneLoading()
    }
  }
}
</script>
