<template>
  <div class="flex justify-center">
    <!-- Page 1 -->
    <span v-if="subPage === 0" class="flex flex-col items-center text-center animate-fadeIn">
      <div class="*:mb-4 mb-4">
        <h1 v-if="userName">Hello {{ userName }}, welcome to Bolster.</h1>
        <h1 v-else>Welcome to Bolster.</h1>
        <p class="text-lg text-surface-600 max-w-[800px]">
          We're thrilled to have you onboard. Before you dive into the amazing features our platform
          has to offer, we'd like to tailor your experience.
        </p>
      </div>
      <div class="max-w-[580px] bg-flame-white rounded p-8 shadow-lg shadow-surface-300">
        <img :src="listImage" class="my-6 mb-12" />
        <p class="text-xl text-center my-8">
          To get started, we have <b>{{ enableKYC ? '5' : '4' }}</b> easy steps for you to complete
          before accessing the platform.
        </p>
        <div class="flex w-100">
          <FormButton @click="nextSubPage" text="Next" rightArrow />
        </div>
      </div>
    </span>

    <!-- Page 2 -->
    <span v-if="subPage === 1" class="flex flex-col items-center text-center animate-fadeIn">
      <div class="*:mb-4 mb-4">
        <h1>Please take a moment to:</h1>
      </div>
      <div class="max-w-[580px] bg-flame-white rounded p-8 shadow-lg shadow-surface-300">
        <img :src="graphImage" class="my-6 mb-12" />
        <div class="flex flex-col mx-2 my-8 gap-6">
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'circle-1']" class="mt-1" />
            <p class="ml-3 text-left">Enter your basic information.</p>
          </div>
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'circle-2']" class="mt-1" />
            <p class="ml-3 text-left">Tell us about your work.</p>
          </div>
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'circle-3']" class="mt-1" />
            <p class="ml-3 text-left">Set your company defaults.</p>
          </div>
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'circle-4']" class="mt-1" />
            <p class="ml-3 text-left">Invite your team.</p>
          </div>
          <div v-if="enableKYC" class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'circle-5']" class="mt-1" />
            <p class="ml-3 text-left">
              Gain access to our payments feature and become <b>Bolster Verified</b>.
            </p>
          </div>
        </div>
        <div class="flex w-100 gap-3">
          <FormButton @click="lastSubPage" text="Last" leftArrow muted />
          <FormButton @click="nextSubPage" text="Next" rightArrow />
        </div>
      </div>
    </span>

    <!-- Page 3 -->
    <span v-if="subPage === 2" class="flex flex-col items-center text-center animate-fadeIn">
      <div class="*:mb-4 mb-4">
        <h1>We're here every step of the way.</h1>
      </div>
      <div class="max-w-[580px] bg-flame-white rounded p-8 shadow-lg shadow-surface-300">
        <img :src="shieldImage" class="my-6 mb-12" />
        <div class="flex flex-col mx-2 my-8 gap-6">
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'floppy-disks']" class="mt-1" />
            <p class="ml-3 text-left">
              Your progress will be <b>saved</b> along the way, so feel free to come back later.
            </p>
          </div>
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'hourglass-half']" class="mt-1" />
            <p class="ml-3 text-left">
              The onboarding process will take <b>12 - 15</b> minutes to complete.
            </p>
          </div>
          <div class="flex text-xl">
            <font-awesome-icon :icon="['fas', 'life-ring']" class="mt-1" />
            <p class="ml-3 text-left">
              Use the <b>help</b> button to contact us for support at any point.
            </p>
          </div>
        </div>
        <div class="flex w-100 gap-3">
          <FormButton @click="lastSubPage" text="Last" leftArrow muted />
          <FormButton @click="$emit('nextPage')" text="Let's start" rightArrow />
        </div>
      </div>
    </span>
    <div class="mt-5"></div>
  </div>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'
import listImage from '@/assets/3D_list.png'
import graphImage from '@/assets/3D_graph.png'
import shieldImage from '@/assets/3D_shield.png'

export default {
  name: 'IntakeLanding',
  components: {
    FormButton
  },
  data() {
    return {
      listImage,
      graphImage,
      shieldImage,
      subPage: 0
    }
  },
  props: {
    enableKYC: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    userName() {
      return this.$store.state.session.user.user_fname
    }
  },
  methods: {
    nextSubPage() {
      this.subPage = this.subPage + 1
    },
    lastSubPage() {
      this.subPage = this.subPage - 1
    }
  }
}
</script>
