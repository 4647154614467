<template>
  <div class="flex flex-col w-full gap-y-2">
    <CardList>
      <CardListItem
        v-for="(rate, index) in [...savedRates, ...newRates]"
        :key="index"
        class="last:border-0 border-b border-surface-300 border-dotted"
      >
        <div
          class="flex flex-col md:flex-row md:items-center md:justify-between w-full gap-x-4 gap-y-2 *:gap-y-1"
        >
          <Field
            schema="labor_type:labor_type_name"
            v-model="rate.labor_type_name"
            placeholder="Labor rate name"
            class="!max-w-[45]"
          />

          <div class="flex flex-row items-center gap-x-2">
            <p class="!m-0">$</p>
            <Field
              class="!min-w-28 !w-28"
              schema="labor_type:labor_type_rate_net"
              v-model="rate.labor_type_rate_net"
              placeholder="Rate"
            />
          </div>

          <Choose
            :highlightIfEmpty="true"
            btnClass="!max-w-44 !w-44"
            schema="labor_type:trade_type_ids"
            v-model="rate.trade_type_ids"
            :multiple="true"
            :return-array="true"
            placeholder="Crew types"
          />
        </div>
      </CardListItem>
    </CardList>

    <div class="flex flex-row justify-between">
      <div
        class="flex flex-row items-center gap-x-2 !mb-4 py-3 rounded cursor-pointer text-cement-900 hover:text-cement-800 transition"
        @click="addNewRate"
      >
        <icon icon="plus" />
        <p class="select-none">Add another labor rate</p>
      </div>
      <btn severity="tertiary" size="lg" @click="saveRates" v-if="showSubmit"> Save </btn>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const emptyRate = {
  labor_type_name: '',
  labor_type_rate_net: 0.0,
  trade_type_ids: []
}
const newRates = ref([_.imm(emptyRate)])
const savedRates = ref([])

defineProps({
  showSubmit: {
    default: true
  }
})

onMounted(async () => {
  savedRates.value = await getSavedRates()
})

/**
 * Get saved rates.
 * Retrieves the set of all previously saved labor rates for
 * the currently scoped company.
 */
async function getSavedRates() {
  const { set } = await store.dispatch('LaborType/search', {
    filters: {
      company_id: store.state.session.scope.company
    }
  })
  return set
}

/**
 * Add new rate.
 * Add an empty rate to the stack of labor rates.
 */
async function addNewRate() {
  newRates.value = [...newRates.value, _.imm(emptyRate)]
}

/**
 * Validate rate.
 * Given a labor rate, validates its data.
 * Returns true if valid, false if not.
 */
function validateRate(rate) {
  // Name, rate, and trade type ids cannot be null / undefined
  if (!rate.labor_type_name || !rate.labor_type_rate_net || !rate.trade_type_ids) {
    return false
  }
  // Name can't be empty
  if (rate.labor_type_name === '') {
    return false
  }
  // Rate must be positive and non-zero
  if (rate.labor_type_rate_net <= 0) {
    return false
  }
  // At least one trade type id must be selected
  if (rate.trade_type_ids.length <= 0) {
    return false
  }
  return true
}

/**
 * Save rates.
 * Saves / updates both new and previously
 * saved labor rates.
 */
async function saveRates() {
  // Combine newly added and previously saved rates
  const allRates = [...newRates.value, ...savedRates.value]
  Object.values(allRates).forEach(async (rate) => {
    // If the entered rate passes validation, save it
    if (validateRate(rate)) {
      await store.dispatch('LaborType/save', {
        selected: [rate],
        go: false,
        alert: false
      })
    }
  })
}

defineExpose({
  saveRates
})
</script>
