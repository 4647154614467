<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn">
    <div class="mb-1">
      <h1 class="mb-1">Company information</h1>
      <p class="text-surface-800">
        Filling out basic information helps us to facilitate better communication, improve security,
        and simplify your Bolster experience.
      </p>
    </div>

    <div class="mt-4">
      <span class="flex flex-col mt-6">
        <div class="flex flex-row">
          <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-sm" />
          <p class="ml-1">Company legal name</p>
        </div>
        <field
          v-model="company_name"
          class="!bg-flame-white !w-full !border-surface-300"
          :validate="{ required: true }"
          ref="company_name"
        />
        <p class="text-surface-600/75 text-sm ml-1 mt-0.5">
          The legally registered name of your business as it appears in your tax documents. Example:
          My Company LLC.
        </p>
      </span>

      <span class="flex flex-col mt-6">
        <div class="flex flex-row">
          <p class="ml-1">Company display name</p>
        </div>
        <field
          v-model="company_name_short"
          class="!bg-flame-white !w-full !border-surface-300"
          :validate="{ required: false }"
          ref="company_name_short"
        />
        <p class="text-surface-600/75 text-sm ml-1 mt-0.5">
          The company name that will be publicly displayed to customers, vendors, and
          subcontractors. Example: My Company.
        </p>
      </span>

      <span class="flex flex-col mt-6">
        <div class="flex flex-row">
          <font-awesome-icon :icon="['fas', 'asterisk']" class="text-deep-red-500 text-sm" />
          <p class="ml-1">Company website</p>
        </div>
        <field
          v-model="company_website"
          class="!bg-flame-white !w-full !border-surface-300"
          @change="autoFormatWebsite"
          :validate="{
            required: true
          }"
          ref="company_website"
        />
        <p class="text-surface-600/75 text-sm ml-1 mt-0.5">Example: https://www.website.com.</p>
      </span>

      <span class="flex flex-col mt-8 mb-2">
        <p class="text-xl mx-0 mb-1 mt-3">Upload company logo</p>
        <p class="text-surface-600/75">We accept PNG and JPEG images.</p>
        <image-selector v-model="company_logo_file_id" />
      </span>

      <div class="bg-surface-200 mt-12 h-px" />
      <danger
        v-if="invalidFields.length"
        :rounded="false"
        :flat="true"
        :border="false"
        :highlight="true"
        class="xs mt-3"
      >
        <p class="text-danger">
          The following fields must be filled out correctly: {{ invalidFields.join(', ') }}
        </p>
      </danger>
      <div class="flex mt-3 justify-between">
        <div>
          <FormButton
            @click="saveData('last')"
            ref="lastButton"
            text="Previous"
            leftArrow
            muted
            showLoading
          />
        </div>
        <div>
          <FormButton
            @click="saveData('next')"
            ref="nextButton"
            text="Continue"
            rightArrow
            showLoading
          />
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import FormButton from '@/components/ui/FormButton.vue'

export default {
  name: 'IntakeCompanyDetails',
  mixins: [ObjectManipulator('company', false, 'Company'), BodyMixin],
  components: {
    FormButton
  },
  data() {
    return {
      invalidFields: []
    }
  },
  props: {
    // Used by ObjectManipulator to pull and update company data in the vuex store
    object: Object
  },
  methods: {
    validateData(data) {
      this.invalidFields = []
      if (!this.$refs.company_name.valid) {
        this.invalidFields.push('company legal name')
      }
      const websiteFormat =
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/
      if (!websiteFormat.test(data?.company_website)) {
        this.invalidFields.push('company website')
      }
      if (this.invalidFields.length) {
        return false
      }
      return true
    },
    async saveData(page) {
      const data = {
        company_name: this.company_name,
        company_name_short: this.company_name_short,
        company_website: this.autoFormatWebsite(),
        company_logo_file_id: this.company_logo_file_id
      }
      if (this.validateData(data)) {
        await this.$store.dispatch('Company/submitOnboardingData', {
          companyId: this.$store.state.session.scope.company,
          data
        })
        if (page === 'next') this.$emit('nextPage')
      }
      this.$refs.nextButton.doneLoading()
      if (page === 'last') this.$emit('lastPage')
    },
    autoFormatWebsite() {
      let newWebsite = ''
      if (!this.$refs.company_website.fieldValue) return newWebsite
      let values = this.$refs.company_website.fieldValue.split('.')

      // Do nothing if the field is blank
      if (this.$refs.company_website.fieldValue === '') return

      if (values[0].includes('ww') || values[0].includes('http')) {
        // If the first value is similar to https://www, replace it with exactly that
        values[0] = 'https://www'
        values.forEach((val) => (newWebsite += val !== '' ? val + '.' : ''))
      } else {
        // Otherwise assume values[0] is part of the website name, so prepend https://www
        values.forEach((val) => (newWebsite += val !== '' ? val + '.' : ''))
        newWebsite = 'https://www.' + newWebsite
      }
      // Remove trailing dot and update company website
      newWebsite = newWebsite.substring(0, newWebsite.length - 1)
      this.company_website = newWebsite
      return newWebsite
    }
  }
}
</script>
