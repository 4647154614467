<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn bg-surface-100">
    <div class="mb-1">
      <h1 class="mb-1">Customer Identification Program Notice</h1>
      <span class="ml-4">
        <ul>
          <li class="mb-4">
            To help the government fight the funding of terrorism and money laundering activities,
            Federal law requires all financial institutions to obtain, verify, and record
            information that identifies each person who opens an account.
          </li>
          <li class="mb-4">
            This Notice answers some questions about Bolster's Customer Identification Program and
            what this means for you.
          </li>
          <li class="mb-4">
            When you open an a Bolster Business account, we will ask for your name, address, date of
            birth, taxpayer identification number (social security number or employer identification
            number) and other information that will allow us to identify you.
          </li>
          <li class="mb-4">
            We may also ask to see your driver’s license or other identifying documents.
          </li>
          <li class="mb-4">
            This Notice answers some questions about Bolster's Customer Identification Program and
            what this means for you.
          </li>
          <li class="mb-4">
            When you open an a Bolster Business account, we will ask for your name, address, date of
            birth, taxpayer identification number (social security number or employer identification
            number) and other information that will allow us to identify you.
          </li>
          <li class="mb-4">
            We may also ask to see your driver’s license or other identifying documents.
          </li>
        </ul>
      </span>
    </div>

    <div class="bg-surface-200 mt-12 h-px" />
    <div class="flex mt-3 justify-between">
      <div>
        <FormButton @click="$emit('lastPage')" ref="lastButton" text="Previous" leftArrow muted />
      </div>
      <div>
        <FormButton
          @click="$emit('nextPage')"
          ref="nextButton"
          text="Continue"
          rightArrow
          showLoading
        />
      </div>
    </div>
  </span>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'

export default {
  name: 'IntakeAcceptTerms',
  components: {
    FormButton
  },
  data() {
    return {
      showDanger: false
    }
  },
  methods: {
    onInput(accepted) {
      if (accepted) {
        this.showDanger = false
        this.$emit('nextPage')
      }
      this.showDanger = true
    }
  }
}
</script>
