<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn bg-surface-100">
    <div class="mb-1">
      <h1 class="mb-1">Terms and conditions</h1>
      <p class="text-surface-800 mb-4">
        Before we start, carefully read the Bolster terms and conditions and privacy policy. When
        you're done, accept the terms to proceed.
      </p>
    </div>

    <AcceptTerms
      v-model="termsAccepted"
      @input="onInput"
      :companyId="this.$store.state.session.company.company_id"
      :userId="this.$store.state.session.user.user_id"
      :forCompany="true"
      :forUser="false"
      :showCheck="false"
    />

    <div class="bg-surface-200 mt-12 h-px" />
    <danger
      v-if="showDanger"
      :rounded="false"
      :flat="true"
      :border="false"
      :highlight="true"
      class="xs mt-3"
    >
      <p class="text-danger">Please accept the terms and conditions before proceeding.</p>
    </danger>
    <div class="flex mt-3 justify-between">
      <div>
        <FormButton @click="$emit('lastPage')" ref="lastButton" text="Previous" leftArrow muted />
      </div>
    </div>
  </span>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'
import AcceptTerms from '@/components/legal/AcceptTerms.vue'

export default {
  name: 'IntakeAcceptTerms',
  components: {
    FormButton,
    AcceptTerms
  },
  data() {
    return {
      showDanger: false
    }
  },
  methods: {
    onInput(accepted) {
      if (accepted) {
        this.showDanger = false
        this.$emit('nextPage')
      }
      this.showDanger = true
    }
  }
}
</script>
