<template>
  <span class="flex justify-center">
    <div class="flex flex-col text-center items-center max-w-[800px] animate-fadeIn">
      <img :src="clipboardImage" class="my-6 mb-12" />
      <h1 class="mb-3">We're not quite ready yet</h1>
      <p class="text-lg text-surface-600">
        Your admin hasn't finished setting up your company's profile. Once they do, you can start
        building better with everything you need to manage your projects.
      </p>
      <p class="mt-20 mb-4 text-2xl">In the meantime..</p>
      <div
        class="flex items-center mb-4 underline underline-offset-4 hover:text-surface-600 transition cursor-pointer"
      >
        <p @click="goToUniversity">Bolster university</p>
        <font-awesome-icon icon="arrow-up-right-from-square" class="ml-2" />
      </div>
      <div
        class="flex items-center mb-4 underline underline-offset-4 hover:text-surface-600 transition cursor-pointer"
      >
        <p @click="goToProfile">Edit your profile</p>
        <font-awesome-icon icon="arrow-up-right-from-square" class="ml-2" />
      </div>
      <div
        class="mt-16 hover:text-surface-600 max-w-fit transition py-3 pr-3 cursor-pointer"
        @click="goToHome"
      >
        <p class="text-2xl underline underline-offset-8 decoration-1">Back to home</p>
      </div>
    </div>
  </span>
</template>

<script>
import clipboardImage from '@/assets/3D_clipboard.png'

export default {
  name: 'IntakeNotAdmin',
  data() {
    return {
      clipboardImage
    }
  },
  methods: {
    goToUniversity() {
      c.openLink('https://www.youtube.com/@BolsterBuilt/videos')
    },
    goToProfile() {
      this.$router.push({ name: 'Profile' })
    },
    goToHome() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
