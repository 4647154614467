<template>
  <div
    class="flex flex-col mb-5 max-h-40 animate-fadeIn *:flex *:flex-row *:items-center *:mb-4 *:gap-x-3"
  >
    <div
      :class="[
        page >= 2 ? '' : 'text-surface-600/50',
        page === 2 ? 'underline underline-offset-4' : ''
      ]"
    >
      <font-awesome-icon :icon="['fas', 'circle-1']" />
      <p>Notice</p>
      <font-awesome-icon v-if="page > 2" :icon="['fas', 'circle-check']" class="text-matcha-500" />
    </div>
    <div
      :class="[
        page >= 3 ? '' : 'text-surface-600/50',
        page === 3 ? 'underline underline-offset-4' : ''
      ]"
    >
      <font-awesome-icon :icon="['fas', 'circle-2']" />
      <p>Company information</p>
      <font-awesome-icon v-if="page > 3" :icon="['fas', 'circle-check']" class="text-matcha-500" />
    </div>
    <div
      :class="[
        page >= 4 ? '' : 'text-surface-600/50',
        page === 4 ? 'underline underline-offset-4' : ''
      ]"
    >
      <font-awesome-icon :icon="['fas', 'circle-3']" />
      <p>Tell us about your work</p>
      <font-awesome-icon v-if="page > 4" :icon="['fas', 'circle-check']" class="text-matcha-500" />
    </div>
    <div
      :class="[
        page >= 5 ? '' : 'text-surface-600/50',
        page === 5 ? 'underline underline-offset-4' : ''
      ]"
    >
      <font-awesome-icon :icon="['fas', 'circle-4']" />
      <p>Company defaults</p>
      <font-awesome-icon v-if="page > 5" :icon="['fas', 'circle-check']" class="text-matcha-500" />
    </div>
    <div
      :class="[
        page >= 6 ? '' : 'text-surface-600/50',
        page === 6 ? 'underline underline-offset-4' : ''
      ]"
    >
      <font-awesome-icon :icon="['fas', 'circle-5']" />
      <p>Invite your team</p>
      <font-awesome-icon v-if="page > 6" :icon="['fas', 'circle-check']" class="text-matcha-500" />
    </div>
    <div
      v-if="enableKYC"
      :class="[
        page >= 7 ? '' : 'text-surface-600/50',
        page === 7 ? 'underline underline-offset-4' : ''
      ]"
    >
      <font-awesome-icon :icon="['fas', 'circle-6']" />
      <p>Streamline your payments</p>
      <font-awesome-icon v-if="page > 7" :icon="['fas', 'circle-check']" class="text-matcha-500" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntakeNavigation',
  props: {
    page: {
      type: Number,
      default: 0
    },
    enableKYC: {
      type: Boolean,
      default: true
    }
  }
}
</script>
