<template>
  <div class="max-h-56 mb-5 animate-fadeIn">
    <font-awesome-icon :icon="['fas', 'life-ring']" class="text-3xl mb-1" />
    <p class="text-xl mb-1">Need some help?</p>
    <p class="text-surface-600/75 mb-4">Find help topics or contact us</p>
    <div
      class="flex items-center mb-4 underline underline-offset-4 hover:text-surface-600 transition cursor-pointer"
    >
      <p @click="goToSupport">Technical support</p>
      <font-awesome-icon icon="arrow-up-right-from-square" class="ml-2" />
    </div>
    <div
      class="flex items-center mb-4 underline underline-offset-4 hover:text-surface-600 transition cursor-pointer"
    >
      <p @click="goToUniversity">Bolster university</p>
      <font-awesome-icon icon="arrow-up-right-from-square" class="ml-2" />
    </div>
    <div
      class="flex items-center mb-4 underline underline-offset-4 hover:text-surface-600 transition cursor-pointer"
    >
      <p @click="goToBillingSupport">Billing support</p>
      <font-awesome-icon icon="arrow-up-right-from-square" class="ml-2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntakeHelp',
  methods: {
    async goToBillingSupport() {
      const link = await this.$store.dispatch('Keyvalue/get', {
        key: 'billing_link',
        global: true
      })
      c.openLink(link)
    },
    async goToSupport() {
      const link = await this.$store.dispatch('Keyvalue/get', {
        key: 'support_link',
        global: true
      })
      c.openLink(link)
    },
    goToUniversity() {
      c.openLink('https://www.youtube.com/@BolsterBuilt/videos')
    }
  }
}
</script>
